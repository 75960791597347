<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        Copyright &copy; 2023 魔力猫
        <!-- <a href="#"><strong>WebStack</strong></a>
        design by <a href="http://viggoz.com" target="_blank"><strong>Viggo</strong></a> -->
      </div>

      <div class="go-up">
        <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style></style>
